<template>
    <iframe :src="iframeSrc" />
</template>

<script>
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters(["getPageOptions"]),
        selectedEntrance() {
            const selectedEntrance = localStorage.getItem("selectedEntrance");
            let thisEntrance = selectedEntrance
                ? this.gateOptions.entrances.find(
                      ({ slug }) => slug === selectedEntrance
                  )
                : null;

            if (!thisEntrance) {
                thisEntrance = this.gateOptions.entrances.find(
                    ({ slug }) => slug === this.gateOptions.defaultEntrance
                );
            }

            return thisEntrance;
        },
        iframeSrc() {
            return (
                this.gateOptions.assetsHost +
                this.selectedEntrance.animationIframePath
            );
        },
        gateOptions() {
            return this.getPageOptions("gate");
        }
    },
    mounted() {
        this.setupListener();
        localStorage.removeItem("selectedEntrance");
    },
    methods: {
        continueToMeeting() {
            const path = localStorage.getItem("storedPath")
                ? localStorage.getItem("storedPath")
                : "/";
            this.$router.push(path);
        },
        setupListener() {
            window.addEventListener("message", (msg) => {
                if (
                    msg.origin === this.gateOptions.assetsHost &&
                    msg.data === "animationFinished"
                ) {
                    this.continueToMeeting();
                }
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../styles/views/entrances.scss";
</style>
